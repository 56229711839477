var render = function render(){
  var _vm$data$Currency2, _vm$data$Currency3, _vm$data$Currency4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "template",
    attrs: {
      "id": "confirmationLetter"
    }
  }, [_vm._m(0), _c('div', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.data['PO Date']) + " ")]), _c('table', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-bottom": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_c('center', [_c('b', [_vm._v(_vm._s(_vm.data['Account Name']))]), _c('br'), _vm._v(" PO #: " + _vm._s(_vm.data['PO Number']) + " ")])], 1)]), _c('tr', {
    staticStyle: {
      "border-bottom": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "100%",
      "border-top": "1px solid black",
      "border-bottom": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "border-right": "1px solid black",
      "width": "33%",
      "text-align": "center"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Warehouse Name")]), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Tax Warehouse Name']) + " ")]), _c('td', {
    staticStyle: {
      "border-right": "1px solid black",
      "width": "33%",
      "text-align": "center"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Warehouse Keeper #")]), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Authorized WH Keeper Number']) + " ")]), _c('td', {
    staticStyle: {
      "width": "33%",
      "text-align": "center"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Tax Warehouse #")]), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Tax Warehouse Number']) + " ")])])])])]), _c('tr', {
    staticStyle: {
      "border-bottom": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    }
  }, [_c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "100%",
      "font-size": "11px"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "center",
      "width": "50%"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Bill To:")]), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Street'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Postal Code']) + " " + _vm._s(_vm.data.Distributor['City'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Country']) + " ")]), _c('td', {
    staticStyle: {
      "text-align": "center",
      "width": "50%"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Ship To:")]), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Ship Street'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Ship Postal Code']) + " " + _vm._s(_vm.data.Distributor['Ship City'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Ship Country']) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.Distributor['Billing Contact'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Billing Email']) + " ")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.Distributor['Shipping Contact'])), _c('br'), _vm._v(" " + _vm._s(_vm.data.Distributor['Shipping Email']) + " ")])])])])])]), _c('table', {
    staticStyle: {
      "border": "0",
      "font-size": "11px",
      "margin-top": "5px",
      "border-collapse": "collapse"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Order Confirmation #:")]), _vm._v(" " + _vm._s(_vm.data['ID']) + " ")]), _vm._m(1), _vm._m(2), _vm._m(3)]), _c('tr', [_c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Estimated Ship Date:")]), _vm._v(" " + _vm._s(_vm.data['Est Finish Date']) + " ")]), _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Warehouse:")]), _vm._v(" " + _vm._s(_vm.data['Warehouse'].label) + " ")]), _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Hours:")]), _vm._v(" " + _vm._s(_vm.data['Warehouse Hours']) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-bottom": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("VAT Nr-Businesspartner:")]), _vm._v(" " + _vm._s(_vm.data.Distributor['FEIN']) + " ")]), _vm._m(4), _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-bottom": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" DELIVERY UNDER EXCISE BOND SHIPPING ")]), _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black",
      "border-bottom": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Payment Terms:")]), _vm._v(" " + _vm._s(_vm.data.Distributor['Terms']) + " ")])])]), _c('table', {
    staticClass: "order-table",
    staticStyle: {
      "margin-top": "5px",
      "page-break-inside": "avoid"
    }
  }, [_vm._m(5), _c('tbody', {
    staticStyle: {
      "border": "0",
      "font-size": "11px"
    }
  }, [_vm._l(_vm.items, function (item, i) {
    var _vm$data$Currency;
    return _c('tr', {
      key: item.ID || i
    }, [_c('td', {
      staticStyle: {
        "width": "2em"
      }
    }, [_c('div', {
      staticClass: "left-btn hide-in-print",
      on: {
        "click": function click() {
          return _vm.items.splice(i, 1);
        }
      }
    }, [_vm._v(" ➖ ")]), _vm._v(" " + _vm._s(i + 1) + " ")]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "12em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'productName');
        }
      }
    }, [_vm._v(" " + _vm._s(item.productName) + " ")])]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "6em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'status');
        }
      }
    }, [_vm._v(" " + _vm._s(item.status) + " ")])]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "6em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'class');
        }
      }
    }, [_vm._v(" " + _vm._s(item.class) + " ")])]), _c('td', {
      staticStyle: {
        "padding": "0",
        "width": "7em"
      }
    }, [_c('span', {
      staticStyle: {
        "display": "inline-block",
        "width": "3em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'alcByVol');
        }
      }
    }, [_vm._v(" " + _vm._s(item.alcByVol) + " ")]), _c('span', [_vm._v("%")])]), _c('td', {
      staticStyle: {
        "padding": "0",
        "width": "7em"
      }
    }, [_c('span', {
      staticStyle: {
        "display": "inline-block",
        "width": "2em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'bottleSize');
        }
      }
    }, [_vm._v(" " + _vm._s(item.bottleSize) + " ")]), _c('span', [_vm._v("ml")])]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "6em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'bottles');
        }
      }
    }, [_vm._v(" " + _vm._s(item.bottles) + " ")])]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "6em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'cases');
        }
      }
    }, [_vm._v(" " + _vm._s(item.cases) + " ")])]), _c('td', {
      staticStyle: {
        "padding": "0"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "6em"
      },
      attrs: {
        "contenteditable": "true"
      },
      on: {
        "blur": function blur($event) {
          return _vm.onInput($event.target.textContent, item, 'pricePerCase');
        }
      }
    }, [_vm._v(" " + _vm._s(item.pricePerCase) + " ")])]), _c('td', {
      staticStyle: {
        "width": "10em"
      },
      attrs: {
        "contenteditable": "false"
      }
    }, [_vm._v(" " + _vm._s((_vm$data$Currency = _vm.data['Currency']) === null || _vm$data$Currency === void 0 ? void 0 : _vm$data$Currency.label) + " " + _vm._s(item.cases * item.pricePerCase) + " ")])]);
  }), _c('tr', {
    staticStyle: {
      "border": "0"
    }
  }, [_c('td', [_vm.items.length < 10 ? _c('div', {
    staticClass: "left-btn hide-in-print",
    on: {
      "click": function click() {
        return _vm.items.push({
          ID: Math.random(),
          productName: '',
          status: '',
          class: '',
          alcByVol: '0',
          cases: '0',
          bottleSize: '0',
          bottles: '0',
          pricePerCase: '0',
          lineCost: '0'
        });
      }
    }
  }, [_vm._v(" ➕ ")]) : _vm._e()])])], 2)]), _c('table', {
    staticStyle: {
      "page-break-inside": "avoid",
      "border-collapse": "collapse",
      "font-size": "11px",
      "width": "100%"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "rowspan": "4",
      "contenteditable": "true"
    }
  }), _vm.orderDiscount > 0 ? _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-weight": "bold",
      "width": "38%"
    }
  }, [_vm._v(" Order Amount: ")]) : _vm._e(), _vm.orderDiscount > 0 ? _c('td', {
    staticStyle: {
      "text-align": "left",
      "font-weight": "bold",
      "width": "12%"
    }
  }, [_vm._v(" " + _vm._s((_vm$data$Currency2 = _vm.data['Currency']) === null || _vm$data$Currency2 === void 0 ? void 0 : _vm$data$Currency2.label) + " " + _vm._s(_vm.orderTotal.toFixed(2)) + " ")]) : _vm._e()]), _vm.orderDiscount > 0 ? _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right",
      "font-weight": "bold",
      "width": "38%"
    }
  }, [_vm._v(" Discount: ")]), _c('td', {
    staticStyle: {
      "text-align": "left",
      "font-weight": "bold",
      "width": "12%"
    }
  }, [_vm._v(" " + _vm._s((_vm$data$Currency3 = _vm.data['Currency']) === null || _vm$data$Currency3 === void 0 ? void 0 : _vm$data$Currency3.label) + " " + _vm._s(_vm.orderDiscount.toFixed(2)) + " ")])]) : _vm._e(), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right",
      "font-weight": "bold",
      "width": "38%"
    }
  }, [_vm._v(" Total Order: ")]), _c('td', {
    staticStyle: {
      "text-align": "left",
      "font-weight": "bold",
      "width": "12%"
    }
  }, [_vm._v(" " + _vm._s((_vm$data$Currency4 = _vm.data['Currency']) === null || _vm$data$Currency4 === void 0 ? void 0 : _vm$data$Currency4.label) + " " + _vm._s((_vm.orderTotal - _vm.orderDiscount).toFixed(2)) + " ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right",
      "text-decoration": "underline",
      "width": "38%"
    }
  }, [_vm._v(" Bottles: ")]), _c('td', {
    staticStyle: {
      "text-align": "left",
      "width": "12%"
    }
  }, _vm._l(_vm.groupedItems, function (total, size) {
    return _c('div', {
      key: size
    }, [_vm._v(" " + _vm._s(size) + " ml: " + _vm._s(total) + " ")]);
  }), 0)])]), _c('table', {
    staticStyle: {
      "page-break-inside": "avoid"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_c('center', [_c('b', [_vm._v("Bank Info Europe")])])], 1)]), _vm._m(6), _vm._m(7), _c('tr', [_c('td', {
    staticStyle: {
      "border": "1px solid black",
      "border-top": "1px solid black"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_c('center', [_c('b', [_vm._v("Please reference your PO")])])], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticStyle: {
      "border": "0.5px solid black"
    },
    attrs: {
      "cellpadding": "10"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "24px",
      "text-align": "center",
      "width": "100%",
      "letter-spacing": "10px",
      "margin": "0",
      "line-height": "normal",
      "font-family": "'Commissioner', sans-serif",
      "font-weight": "700"
    },
    attrs: {
      "id": "logoId"
    }
  }, [_vm._v(" PRIVATE PARTY IMPORTS ")])])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "0",
      "text-align": "center"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_vm._v(" OBERSCHWARZENBACH 9 | 4865 NUSSDORF | AUSTRIA ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "0",
      "text-align": "center"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_vm._v(" PHONE +43 681 20650047 ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "0",
      "text-align": "center"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_vm._v(" info@privatepartyimports.com | www.privatepartyimports.com ")])]), _c('tr', [_c('td', {
    staticStyle: {
      "border": "0",
      "text-align": "center"
    },
    attrs: {
      "colspan": "2",
      "contenteditable": "true"
    }
  }, [_vm._v(" CZ VAT No. CZ684498491 ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("VAT Rate:")]), _vm._v(" 0% ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Country of Origin:")]), _vm._v(" USA ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-right": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_c('b', [_vm._v("Shipping:")]), _vm._v(" EXW ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "border-top": "1px solid black",
      "border-left": "1px solid black",
      "border-bottom": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" ALL PRODUCTS CERTIFIED ORGANIC "), _c('br'), _vm._v(" BY MOSA (US-ORG-025) - Production "), _c('br'), _vm._v(" BY SLK (AT-BIO-501) - Trade ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_c('td', [_vm._v("#")]), _c('td', [_vm._v("Product")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Class")]), _c('td', [_vm._v("ABV")]), _c('td', [_vm._v("Size")]), _c('td', [_vm._v("# Bottles")]), _c('td', [_vm._v("# Cases")]), _c('td', [_vm._v("Case Price")]), _c('td', [_vm._v("Line Cost")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "border-left": "1px solid black",
      "border-top": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" Bank Name: Sparkasse Salzburg ")]), _c('td', {
    staticStyle: {
      "border-left": "1px solid black",
      "border-right": "1px solid black",
      "border-top": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" Account Holder Name: Private Party Imports GmbH ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticStyle: {
      "border-left": "1px solid black",
      "border-top": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" IBAN: AT962040400041989823 ")]), _c('td', {
    staticStyle: {
      "border-left": "1px solid black",
      "border-right": "1px solid black",
      "border-top": "1px solid black"
    },
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" BIC/SWIFT: SBGSAT2SXXX ")])]);

}]

export { render, staticRenderFns }