<template>
  <div class="template" id="confirmationLetter">
    <table cellpadding="10" style="border:0.5px solid black">
      <tr>
        <td colspan="2" style="border:0;">
          <div
            id="logoId"
            style="font-size: 24px; text-align: center; width: 100%; letter-spacing: 10px;margin: 0;line-height:normal;font-family: 'Commissioner', sans-serif; font-weight:700"
          >
            PRIVATE PARTY IMPORTS
          </div>
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          OBERSCHWARZENBACH 9 | 4865 NUSSDORF | AUSTRIA
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          PHONE +43 681 20650047
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          info@privatepartyimports.com | www.privatepartyimports.com
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          style="border:0; text-align:center;"
          contenteditable="true"
        >
          CZ VAT No. CZ684498491
        </td>
      </tr>
    </table>

    <!-- Date -->
    <div style="text-align:right;" contenteditable="true">
      {{ data['PO Date'] }}
    </div>

    <!-- Client and Warehouse Information Table -->
    <table cellpadding="10" style="border:0;">
      <tr style="border-top:1px solid black;border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black;">
        <td colspan="2" contenteditable="true">
          <center>
            <b>{{ data['Account Name'] }}</b>
            <br />
            PO #: {{ data['PO Number'] }}
          </center>
        </td>
      </tr>
     
      <tr style="border-bottom:1px solid black;border-left:1px solid black; border-right:1px solid black;">
        <td colspan="2">
          <table style="width:100%; border-top:1px solid black;border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black;">
            <tr>
              <td style="border-right:1px solid black; width:33%; text-align:center;" contenteditable="true">
                <b>Warehouse Name</b><br />
                {{ data.Distributor['Tax Warehouse Name'] }}
              </td>
              <td style="border-right:1px solid black; width:33%; text-align:center;" contenteditable="true">
                <b>Warehouse Keeper #</b><br />
                {{ data.Distributor['Authorized WH Keeper Number'] }}
              </td>
              <td style="width:33%; text-align:center;" contenteditable="true">
                <b>Tax Warehouse #</b><br />
                {{ data.Distributor['Tax Warehouse Number'] }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
     
      <tr style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black;">
        <td colspan="2" style="text-align:center;">
          <table cellpadding="10" style="width:100%; font-size:11px;">
            <tr>
              <td style="text-align:center; width:50%;" contenteditable="true">
                <b>Bill To:</b><br />
                {{ data.Distributor['Street'] }}<br />
                {{ data.Distributor['Postal Code'] }}
                {{ data.Distributor['City'] }}<br />
                {{ data.Distributor['Country'] }}
              </td>
              <td style="text-align:center; width:50%;" contenteditable="true">
                <b>Ship To:</b><br />
                {{ data.Distributor['Ship Street'] }}<br />
                {{ data.Distributor['Ship Postal Code'] }}
                {{ data.Distributor['Ship City'] }}<br />
                {{ data.Distributor['Ship Country'] }}
              </td>
            </tr>
            <tr>
              <td style="text-align:center;" contenteditable="true">
                {{ data.Distributor['Billing Contact'] }}<br />
                {{ data.Distributor['Billing Email'] }}
              </td>
              <td style="text-align:center;" contenteditable="true">
                {{ data.Distributor['Shipping Contact'] }}<br />
                {{ data.Distributor['Shipping Email'] }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
     </table>

    <!-- Order Details -->
    <table cellpadding="10" style="border:0; font-size:11px; margin-top:5px; border-collapse:collapse;">
      <tr>
        <td style="border-top:1px solid black; border-left:1px solid black;" contenteditable="true">
          <b>Order Confirmation #:</b> {{ data['ID'] }}
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black;" contenteditable="true">
          <b>VAT Rate:</b> 0%
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black;" contenteditable="true">
          <b>Country of Origin:</b> USA
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black; border-right:1px solid black;" contenteditable="true">
          <b>Shipping:</b> EXW
        </td>
      </tr>
      <tr>
        <td style="border-top:1px solid black; border-left:1px solid black;" contenteditable="true">
          <b>Estimated Ship Date:</b> {{ data['Est Finish Date'] }}
        </td>
        <td colspan="2" style="border-top:1px solid black; border-left:1px solid black;" contenteditable="true">
          <b>Warehouse:</b> {{ data['Warehouse'].label }}
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black; border-right:1px solid black;" contenteditable="true">
          <b>Hours:</b> {{ data['Warehouse Hours'] }}
        </td>
      </tr>
      <tr>
        <td style="border-top:1px solid black; border-left:1px solid black; border-bottom:1px solid black;" contenteditable="true">
          <b>VAT Nr-Businesspartner:</b> {{ data.Distributor['FEIN'] }}
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black; border-bottom:1px solid black;" contenteditable="true">
          ALL PRODUCTS CERTIFIED ORGANIC <br />
          BY MOSA (US-ORG-025) - Production <br />
          BY SLK (AT-BIO-501) - Trade
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black; border-bottom:1px solid black;" contenteditable="true">
          DELIVERY UNDER EXCISE BOND SHIPPING
        </td>
        <td style="border-top:1px solid black; border-left:1px solid black; border-right:1px solid black; border-bottom:1px solid black;" contenteditable="true">
          <b>Payment Terms:</b> {{ data.Distributor['Terms'] }}
        </td>
      </tr>
     </table>

     <table
     style="margin-top:5px;page-break-inside: avoid;"
     class="order-table"
   >
     <thead>
       <tr style="font-size: 11px">
         <td>#</td>
         <td>Product</td>
         <td>Status</td>
         <td>Class</td>
         <td>ABV</td>
         <td>Size</td>
         <td># Bottles</td>
         <td># Cases</td>
         <td>Case Price</td>
         <td>Line Cost</td>
       </tr>
     </thead>
     <tbody style="border:0; font-size:11px;">
       <tr v-for="(item, i) of items" :key="item.ID || i">
         <td  style="width:2em"  >
           <div
             class="left-btn hide-in-print"
             @click="() => items.splice(i, 1)"
           >
             ➖
           </div>
           {{ i + 1 }}
         </td>

         <td style="padding:0;">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'productName')"
             style="width:12em"  
           >
             {{ item.productName }}
           </div>
         </td>

         <td style="padding:0;">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'status')"
             style="width:6em"
           >
             {{ item.status }}
           </div>
         </td>

         <td style="padding:0;">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'class')"
             style="width:6em"
           >
             {{ item.class }}
           </div>
         </td>

         <td style="padding:0;width:7em">
           <span
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'alcByVol')"
             style="display: inline-block;width:3em"
           >
             {{ item.alcByVol }}
           </span>
           <span>%</span>
         </td>

         <td style="padding:0;width:7em">
           <span
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'bottleSize')"
             style="display: inline-block;width:2em"
           >
             {{ item.bottleSize }}
           </span>
           <span>ml</span>
         </td>
         <td style="padding:0;">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'bottles')"
             style="width:6em"
           >
             {{ item.bottles }}
           </div>
         </td>
         <td style="padding:0">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'cases')"
             style="width:6em"
           >
             {{ item.cases }}
           </div>
         </td>

         <td style="padding:0;">
           <div
             contenteditable="true"
             @blur="onInput($event.target.textContent, item, 'pricePerCase')"
             style="width:6em"
           >
             {{ item.pricePerCase }}
           </div>
         </td>
         <td contenteditable="false" style="width:10em">
           {{ data['Currency']?.label }} {{ item.cases * item.pricePerCase }}
         </td>
       </tr>

       <tr style="border:0;">
         <td>
           <div
             v-if="items.length < 10"
             class="left-btn hide-in-print"
             @click="
               () =>
                 items.push({
                   ID: Math.random(),
                   productName: '',
                   status: '',
                   class: '',
                   alcByVol: '0',
                   cases: '0',
                   bottleSize: '0',
                   bottles: '0',
                   pricePerCase: '0',
                   lineCost: '0'
                 })
             "
           >
             ➕
           </div>
         </td>
       </tr>
     </tbody>
   </table>
    
   <table cellpadding="10" style="page-break-inside: avoid;border-collapse: collapse;font-size:11px;width:100%;">
    <tr>
      <td rowspan="4" style="width:50%" contenteditable="true">
      </td>
      <td v-if="orderDiscount > 0" style="text-align:right;font-weight:bold;width:38%">
        Order Amount:
      </td>
      <td v-if="orderDiscount > 0" style="text-align:left;font-weight:bold;width:12%">
        {{ data['Currency']?.label }} {{ orderTotal.toFixed(2) }}
      </td>
    </tr>
    <tr v-if="orderDiscount > 0">
      <td style="text-align:right;font-weight:bold;width:38%">
        Discount:
      </td>
      <td style="text-align:left;font-weight:bold;width:12%">
        {{ data['Currency']?.label }} {{ orderDiscount.toFixed(2) }}
      </td>
    </tr>
    <tr>
      <td style="text-align:right;font-weight:bold;width:38%">
        Total Order:
      </td>
      <td style="text-align:left;font-weight:bold;width:12%">
        {{ data['Currency']?.label }} {{ (orderTotal - orderDiscount).toFixed(2) }}
      </td>
    </tr>
    <tr>
      <td style="text-align:right;text-decoration:underline;width:38%">
        Bottles:
      </td>
      <td style="text-align:left;width:12%">
        <div v-for="(total, size) in groupedItems" :key="size">
          {{ size }} ml: {{ total }}
        </div>
      </td>
    </tr>
  </table>



<table cellpadding="10" style="page-break-inside: avoid;">
  <tr>
    <td colspan="2" style="border-top:1px solid black; border-left:1px solid black; border-right:1px solid black;" contenteditable="true">
          <center><b>Bank Info Europe</b></center>
        </td>
      </tr>
      <tr>
        <td style="border-left:1px solid black; border-top:1px solid black;" contenteditable="true">
          Bank Name: Sparkasse Salzburg
        </td>
        <td style="border-left:1px solid black; border-right:1px solid black; border-top:1px solid black;" contenteditable="true">
          Account Holder Name: Private Party Imports GmbH
        </td>
      </tr>
      <tr>
        <td style="border-left:1px solid black; border-top:1px solid black;" contenteditable="true">
          IBAN: AT962040400041989823
        </td>
        <td style="border-left:1px solid black; border-right:1px solid black; border-top:1px solid black;" contenteditable="true">
          BIC/SWIFT: SBGSAT2SXXX
        </td>
      </tr>
      <tr>
        <td colspan="2" style="border:1px solid black; border-top:1px solid black;" contenteditable="true">
          <center><b>Please reference your PO</b></center>
        </td>
      </tr>
    </table>


  </div>
</template>

<script>
import { on } from 'https-proxy-agent'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    order_date: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      is_shipping_identical: true,
      billing_address: '<b>billing_address:</b> 123 Main St, Anytown, USA',
      items: [],
      tax_percent: 0,
      shipping: 0,
      item_test: ''
    }
  },

  mounted () {
    /*
    // initialize contenteditable="true" fields manually
    this.$refs.billing_address.innerHTML = this.billing_address
    this.$refs.tax_percent.innerHTML = this.tax_percent
    this.$refs.shipping.innerHTML = this.shipping + '.00'
    */
    this.cloneData()
  },
  computed: {
    groupedItems () {
      return this.items.reduce((acc, item) => {
        if (!acc[item.bottleSize]) acc[item.bottleSize] = 0

        acc[item.bottleSize] += parseInt(item.bottles, 10)

        return acc
      }, {})
    },
    today () {
      var date = new Date()
      return date
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '.')
    },
    next_month () {
      var date = new Date()
      date.setDate(date.getDate() + 30)
      return date
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, '.')
    },

    orderTotal () {
      return this.items.reduce(
        (acc, item) => (acc += item.cases * item.pricePerCase),
        0
      )
    },
    orderDiscount () {
      return parseFloat(this.data['Discount']) || 0
    }
  },
  methods: {
    onInput (value, item, key) {
      console.log('onInput:', value, item, key)
      item[key] = value.trim()
    },
    preventLineBreaks (e) {
      if (e.which == 13) e.preventDefault()
    },
    cloneData () {
      this.items = []
      this.data['items'].forEach(item => {
        this.items.push({
          key: Math.random(),
          productName: item['Finished Product'],
          status: item['Organic Status'],
          class: item['Class'],
          alcByVol: item['Alc By Vol'],
          cases: item['Cases'],
          bottleSize: item['Bottle Size'],
          bottles: item['Bottles'],
          pricePerCase: item['Cost per Case'],
          lineCost: item['Cases'] * item['Cost per Case']
        })
      })
    }
  },
  watch: {
    data: {
      handler (newVal) {
        this.cloneData()

      
      },

      deep: true,
      immediate: true
    },
    items: {
      handler (newVal) {
        //console.log('template.watch.items:', newVal)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Commissioner:wght@100..900&display=swap');

.custom-border {
  border: 0.5px solid black;
}


table {
  width: 100%;
  border-collapse: collapse; 
  border-spacing: 0;
  
}

table td {
  position: relative;
  padding: 2px;
  vertical-align: top;
}

table.order-table td {
  vertical-align: middle;
  padding: 2px;
  
}

table.order-table tr {
  vertical-align: middle;
  padding: 2px;
  border-bottom: 0.5px solid #d4d4d2;
}

table thead td {
  font-weight: bold;
}

input[type='number'],
input[type='text'] {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: #ffffed;
  padding: 3px;
}

*[contenteditable='true'] {
  background: #ffffed;
  cursor: text;
  transition: padding 0.05s linear;
}

span[contenteditable='true']:focus {
  padding: 0 2px;
}

@media print {
  table td {
    background: none;
  }
  *[contenteditable='true'] {
    background: none;
  }
  input {
    background: none;
  }
  .hide-in-print {
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'],
  input[type='text'] {
    -moz-appearance: textfield;
    background: none;
  }
  .no-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table {
    page-break-inside: avoid;
  }
  tr {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  h1, h2, h3, p, div {
    page-break-after: auto;
    page-break-before: auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.order {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.left-btn {
  position: absolute;
  left: -40px;
  top: 2px;
}

.left-btn:hover {
  opacity: 0.6;
}
</style>